/* eslint-disable react/prop-types */
// @flow

import React from 'react';

type Props = {
  label: string,
  input: Object,
  placeholder: string,
  meta: Object,
  errorMsg: string,
  min?: string,  // assuming you want to allow min to be passed in
  type?: string, // assuming you want to allow type to be passed in
}

export default function TextInput(props: Props) {
  const { label, input, placeholder, meta, errorMsg, min, type } = props;

  return (
    <div className="preview-field">
      <div className="label">{label}</div>
      <input
        {...input}                // spread input-related props only
        min={min}                 // explicitly pass min if needed
        aria-label={input.name}   // pass aria-label
        type={type || 'text'}     // default to 'text' if type is not provided
        placeholder={placeholder} // explicitly pass placeholder
      />
      {meta.error && meta.touched && (
        <span className="text-error">{errorMsg || meta.error}</span>
      )}
    </div>
  );
}
