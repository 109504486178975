/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// @flow

import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import type { QuoteFormType } from '../../../../types/QuoteFormTypes';
import { LOBS } from '../../../../types/QuoteFormTypes';
import CustomDatePicker, { allYears, futureYears } from '../../../../components/CustomDatePicker';
import { getArrayListFromValues } from '../../../../utils';
import TimePicker from '../../../../components/TimePicker';


type Props = {
  input: {
    value: {
      startDate: Date,
      endDate: Date,
    },
    name: string,
  },
  quoteData: QuoteFormType,
  startDate: string,
  endDate: string,
  annualStartDate: string,
  annualEndDate: string,
  meta: Object,
  dateTime?: boolean,
  startTime?: boolean,
  endTime?: boolean,
  LOB: string,
  tripType: Object,
}

type State = {
  focused: ?boolean,
  focusedEnd: ?boolean,
  startDate: Date,
  endDate: Date,
}

const manageEndDate = (tripType, startDate, currentEndDate) => {
  const copyStart = startDate.clone();
  if (tripType === 'annual') {
    return copyStart.add(1, 'years').subtract(1, 'days');
  }
  return moment(startDate).isAfter(moment(currentEndDate)) ? copyStart : currentEndDate;
};

class DatesOfTrip extends React.Component<Props, State> {
  state = {
    focused: null,
    focusedEnd: null,
  };

  componentDidMount() {
    if (!document.getElementById('dateNew')) {
      if (document.getElementById('date')) {
        document.getElementById('date');
      }
    }
    if (!document.getElementById('dateInputScreenNew')) {
      if (document.getElementById('DateInput__screen-reader-message-date')) {
        document.getElementById('DateInput__screen-reader-message-date').id = 'dateInputScreenNew';
      }
    }
  }

  componentWillReceiveProps(nextProps): void {
    if (this.props.form && this.props.form.values) {
      if (nextProps.form.values.tripType !== this.props.form.values.tripType && nextProps.form.values.tripType === 'annual') {
        const startDate = moment().diff(this.props.input.value.startDate, 'days') === 0 ? moment().add(1, 'days') : this.props.input.value.startDate;
        this.props.input.onChange({
          ...this.props.input.value,
          startDate,
          endDate: manageEndDate('annual', startDate, this.props.input.value.endDate),
        });
      } else if (nextProps.form.values.tripType !== this.props.form.values.tripType && nextProps.form.values.tripType !== 'annual') {
        this.props.input.onChange({
          ...this.props.input.value,
          startDate: this.props.input.value.startDate,
          endDate: this.props.input.value.startDate,
        });
      }
    }
  }

  render() {
    const { quoteData: { mainQuoteData }, form } = this.props;
    if (!form) return false;
    const isAnnual = form.values.tripType === 'annual';
    return (
      <div className="preview-field">
        {
          mainQuoteData.tripType === 'annual' &&
          <Fragment>
            <CustomDatePicker
              id={this.props.startDate}
              label={this.props.startDate}
              showDefaultInputIcon={this.props.calendarIcon}
              date={this.props.input.value.startDate || moment()}
              years={allYears}
              onDateChange={startDate => this.props.input.onChange({
                // eslint-disable-next-line object-shorthand
                startDate: startDate,
                endDate: startDate.add(1, 'years').subtract(1, 'days'),
              })}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              placeholder={moment().format('L')}
              normalize={(val) => val || moment()}
            />
            {
              this.props.dateTime && this.props.LOB === LOBS.CAR_RENTAL &&
              <div className="container-select">
                <div className="preview-field--responsive preview-field--responsive">
                  <TimePicker
                    first
                    value={this.props.input.value.hours || '00'}
                    list={getArrayListFromValues(0, 23).map(val => (parseInt(val, 10) < 10 ? `0${val}` : val))}
                    onNumberChange={hours => this.props.input.onChange({
                      ...this.props.input.value,
                      hours,
                    })}
                  />

                </div>
                <div className="preview-field--responsive preview-field--responsive">
                  <TimePicker
                    value={this.props.input.value.minutes || '00'}
                    list={['00', '15', '30', '45']}
                    onNumberChange={minutes => this.props.input.onChange({
                      ...this.props.input.value,
                      minutes,
                    })}
                  />
                </div>
              </div>
            }
          </Fragment>
        }

        {
          (!this.props.quoteData.mainQuoteData.tripType || (this.props.quoteData.mainQuoteData.tripType && this.props.quoteData.mainQuoteData.tripType !== 'annual')) &&
          <div className="container-select">
            <div className="preview-field preview-field--responsive" id="start date">
              <CustomDatePicker
                id={this.props.startDate}
                label={isAnnual ? this.props.annualStartDate : this.props.startDate}
                showDefaultInputIcon={this.props.calendarIcon}
                // eslint-disable-next-line no-confusing-arrow
                isOutsideRange={day => !isAnnual ? day.isBefore(moment().startOf('day')) : day.isBefore(moment().add(1, 'days').startOf('day'))}
                date={this.props.input.value.startDate || moment()}
                onDateChange={startDate => this.props.input.onChange({
                  ...this.props.input.value,
                  startDate,
                  endDate: manageEndDate(form.values.tripType, startDate, this.props.input.value.endDate),
                })}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                placeholder={moment().format('L')}
                years={futureYears}
                normalize={(val) => val || moment()}
              />
              {
                this.props.startTime && this.props.LOB === LOBS.CAR_RENTAL &&
                <div className="container-select">
                  <div className="preview-field--responsive preview-field--responsive">
                    <TimePicker
                      first
                      value={this.props.input.value.startHours || '00'}
                      list={getArrayListFromValues(0, 23).map(val => (parseInt(val, 10) < 10 ? `0${val}` : val))}
                      onNumberChange={startHours => this.props.input.onChange({
                        ...this.props.input.value,
                        startHours,
                      })}
                    />
                  </div>
                  <div className="preview-field--responsive preview-field--responsive">
                    <TimePicker
                      value={this.props.input.value.startMinutes || '00'}
                      list={['00', '15', '30', '45']}
                      onNumberChange={startMinutes => this.props.input.onChange({
                        ...this.props.input.value,
                        startMinutes,
                      })}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="preview-field preview-field--responsive" id="end date">
              <CustomDatePicker
                id={this.props.endDate}
                label={isAnnual ? this.props.annualEndDate : this.props.endDate}
                disabled={isAnnual}
                isOutsideRange={day => day.isBefore(this.props.input.value.startDate ? moment(this.props.input.value.startDate) : moment())}
                date={this.props.input.value.endDate || moment()}
                onDateChange={endDate => this.props.input.onChange({ ...this.props.input.value, endDate })}
                focused={this.state.focusedEnd}
                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                placeholder={moment().format('L')}
                years={futureYears}
                // eslint-disable-next-line arrow-parens
                normalize={(val) => val || moment()}
              />
              {
                this.props.endTime && this.props.LOB === LOBS.CAR_RENTAL &&
                <div className="container-select">
                  <div className="preview-field--responsive preview-field--responsive">
                    <TimePicker
                      value={this.props.input.value.endHours || '00'}
                      first
                      list={getArrayListFromValues(0, 23).map(val => (parseInt(val, 10) < 10 ? `0${val}` : val))}
                      onNumberChange={endHours => this.props.input.onChange({
                        ...this.props.input.value,
                        endHours,
                      })}
                    />
                  </div>
                  <div className="preview-field--responsive preview-field--responsive">
                    <TimePicker
                      value={this.props.input.value.enMinutes || '00'}
                      list={['00', '15', '30', '45']}
                      onNumberChange={endMinutes => this.props.input.onChange({
                        ...this.props.input.value,
                        endMinutes,
                      })}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        }
        {this.props.meta.error && this.props.meta.touched &&
        <span className="text-error">{this.props.meta.errorMsg || this.props.meta.error}</span>}
      </div>
    );
  }
}

DatesOfTrip.defaultProps = {
  dateTime: false,
  startTime: false,
  endTime: false,
};

export default connect(state => ({
  form: state.form.quoteFormFront,
}))(DatesOfTrip);
