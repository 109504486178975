/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


type Props = {

}

type State = {
}

class Tiles extends React.Component<Props, State> {
  state = {
  };


  render() {
    return (
      <div className="tile">
        <div className="tile-title" style={{ backgroundColor: this.props.color }}>
          <span>{this.props.title}</span>
        </div>
        <div className="tile-content">
          <span className="rich-text-content" dangerouslySetInnerHTML={{ __html: this.props.content }} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({}),
  {},
)(Tiles));
