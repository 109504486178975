/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
/* eslint-disable no-bitwise */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
// @flow

import React, { Fragment } from 'react';
import Tooltip from 'rc-tooltip';
import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap.css';
import { FORM_ROOT_PAGE_ROUTE } from '../../../const';
import type { ContractListRS, Coverage, UnderwritingConditions } from '../../../types/QuotationTypes';
import { convertHex } from '../../../utils';
import { buildFrontPath } from '../../../routes';
import { displayPrice, extractContractIptTax } from '../../../network/api/eqsApi';
import TermsAndConditions from './TermsAndConditions';
import type { DCXType } from '../../../../../admin-ui/src/types/DCXTypes';

type Props = {
  contract: ContractListRS,
  quoteFormFront: Object,
  history: Object,
  quotation: Object,
  match: Object,
  color: string,
  quotationIptTaxesLabel: ?string,
  showTaxes: boolean,
  DCX: DCXType,  
  IsPromoEnable: bool,
  language: string,
}
type State = {
  detailOpen: boolean
}
type InfoDetail = {
  name: string,
  htmlValue: ?string,
  description: ?string,
}

const toDetailArray = (coverages: ?Coverage[], underwritingConditions: ?UnderwritingConditions[]) => {
  const details: InfoDetail[] = [];
  if (coverages) {
    for (const coverage of coverages) {
      details.push({
        name: coverage.name,
        htmlValue: coverage.coverageContent,
        description: coverage.description,
      });
    }
  }
  if (underwritingConditions) {
    for (const condition of underwritingConditions) {
      details.push({
        name: condition.shortDescription,
        htmlValue: condition.underwritingCondition,
        description: condition.description,
      });
    }
  }
  return details;
};

class ProductOffer extends React.Component<Props, State> {
  state = {
    detailOpen: false,
  };

  validateHasDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length > 0) {
      if (promotionCode && discountAmount > 0 && totalDiscountAmount > 0 && grossPremium < originalGrossPremium) {
        result = true;
      }    
    }   
    /*
    console.log( "HasDiscount: " + result      
      + " | IsPromoEnable: " + this.props.IsPromoEnable    
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );     
    */
    return result;
  }

  validateHasZeroDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length > 0) {
      if (promotionCode && discountAmount === 0 && totalDiscountAmount === 0 && grossPremium === originalGrossPremium) {
        result = true;
      }    
    }
    /*
    console.log( "HasZeroDiscount: " + result    
      + " | IsPromoEnable: " + this.props.IsPromoEnable      
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );
    */
    return result;
  }
  
  validateHasNoDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length === 0) {            
      if (grossPremium === originalGrossPremium) {
        result = true;
      }   
    }    
    /*
    console.log( "HasNoDiscount: " + result         
      + " | IsPromoEnable: " + this.props.IsPromoEnable 
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );
    */
    return result;
  }  

  render() {
    const iptTax = extractContractIptTax(this.props.contract);
    const detailsArray = toDetailArray(this.props.contract.coverages, this.props.contract.underwritingConditions);
    const color = this.props.color;
    const colorLight = convertHex(color, 40);

    console.clear();

    const promotionCode = (this.props.quoteFormFront.promoCode) ? this.props.quoteFormFront.promoCode : "[Empty]";
    const HasDiscount = this.validateHasDiscount(this.props.contract); 
    const HasZeroDiscount = this.validateHasZeroDiscount(this.props.contract);  
    const HasNoDiscount = this.validateHasNoDiscount(this.props.contract);  

    console.log( "HasDiscount: " + HasDiscount               
      + " | HasNoDiscount: " + HasNoDiscount
      + " | HasZeroDiscount: " + HasZeroDiscount      
    );

    return (
      <Fragment>
        <div className="offer-container" style={{ background: color }}>
          <div className="title">
            {this.props.contract.productOfferingName}
          </div>
          <div className="right-container">
            <div className="price-container">
              {                
                !this.props.IsPromoEnable &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }
              {
                this.props.IsPromoEnable && HasNoDiscount &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }      
              {
                this.props.IsPromoEnable && HasZeroDiscount &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }     
              {
                this.props.IsPromoEnable && HasDiscount && 
                  <span className="price">
                    {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} / <s>{  displayPrice(this.props.contract.recommendedPremium.originalGrossPremium.amount) }</s> {this.props.contract.recommendedPremium.grossPremium.currency}
                  </span>
                
              }                                    
              {
                this.props.showTaxes &&
                iptTax &&
                <span className="ipt-tax">
                  {this.props.quotationIptTaxesLabel} {displayPrice(iptTax.totalTaxAmount.amount)} {iptTax.totalTaxAmount.currency}
                </span>
              }
            </div>
            <button
              className="button"
              onClick={() => {
                localStorage.setItem('contract', JSON.stringify(this.props.contract));
                this.props.history.push({
                  pathname: buildFrontPath(this.props.match.params, `${FORM_ROOT_PAGE_ROUTE}/${this.props.nextStep}`),
                  search: window.location.search,
                  state: {
                    quoteFormFront: this.props.quoteFormFront,
                    contract: this.props.contract,
                    DCX: this.props.DCX,
                  },
                });
              }
              }
            >
              {this.props.quotation.select}
            </button>
          </div>
        </div>
        <div className="details-container">
          <div
            className="show-details"
            style={{ color: color, background: colorLight }}
            onClick={() => this.setState(prevState => ({ detailOpen: !prevState.detailOpen }))}
          >
            {
              this.state.detailOpen ?
                <Fragment>
                  <i className="fa fa-minus-circle" />{' '}
                  {this.props.quotation.replysDetails}
                </Fragment>
                :
                <Fragment>
                  <i className="fa fa-plus-circle" />{' '}
                  {this.props.quotation.viewDetails}
                </Fragment>

            }

          </div>
          <div className={this.state.detailOpen ? 'open' : 'closed'} style={{ border: `1px solid ${colorLight}` }}>
            {
              detailsArray.map((detail, index) => (
                <div className="details" style={index & 1 ? { backgroundColor: colorLight } : {}} key={detail.name}>
                  <div className="name">{detail.name}
                    {detail.description &&
                    <Tooltip
                      placement="right"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0.1}
                      trigger={['hover']}
                      overlay={<div>{detail.description}</div>}
                    >
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                    }
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: detail.htmlValue }} />
                </div>
              ))
            }
          </div>
          <div style={{ background: color }}>
            <TermsAndConditions offer={this.props.contract} quoteSummaryTranslation={this.props.siteData.quoteForm.quoteSummaryTranslation} />
          </div>
          {
            this.props.IsPromoEnable && !HasZeroDiscount && !HasDiscount && HasNoDiscount && promotionCode !== "[Empty]" &&
            <div className="show-promo-error" style={{ color: color, background: colorLight }} >          
              { this.props.quotation.errorPromoCode }       
            </div>
          }           
          {
            this.props.IsPromoEnable && !HasNoDiscount && !HasDiscount && HasZeroDiscount &&
            <div className="show-promo-error" style={{ color: color, background: colorLight }} >          
              { this.props.quotation.errorPromoCode }       
            </div>
          }           
        </div>
      </Fragment>
    );
  }
}

export default connect(state => ({
  DCX: state.site.DCX,
  siteData: state.site.config,
}))(ProductOffer);
