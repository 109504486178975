/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';
import moment from 'moment';
import {
  displayPrice,
  extractContractIptTax,
} from '../../../network/api/eqsApi';
import type { DCXType } from '../../../types/DCXTypes';
import type { ContractListRS } from '../../../types/QuotationTypes';
import type { QuoteFormType } from '../../../types/QuoteFormTypes';
import TermsAndConditions from './TermsAndConditions';

type Props = {
  DCX: DCXType,
  quoteForm: QuoteFormType,
  quoteFormFront: Object,
  offer: ContractListRS,
  callToAction: string,
  detailSummaryTitleColor: string,
  detailSummaryPanelColor: string,
  countries: Object[],
  quotationIptTaxesLabel: ?string,
  showTaxes: boolean,
}

function orElse(valueIfPresent: any, or: any) {
  return valueIfPresent || or;
}

export default function Summary(props: Props) {
  const { agebandLabels } = props.quoteForm.quoteLabelTranslation.quoteLabelTranslation;
  const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));
  const travellerTranslation = (ageband) => {
    if (JSON.parse(agebandLabels)[ageband] !== '') {
      return JSON.parse(agebandLabels)[ageband];
    }
    return ageband;
  };
  if (props.DCX === null || !props.quoteForm) {
    return false;
  }
  const iptTax = extractContractIptTax(props.offer);
  const countryName = countryCode => orElse(
    props
      .countries
      .find(country => country.countryCode === countryCode),
    { countryName: props.quoteForm.mainQuoteData.destination === 'geozone' || props.quoteForm.additionnalQuoteData.destination ? props.geozones[countryCode] : '' },
  )
    .countryName;

  const IsPromocodeEnable = props.quoteForm.additionnalQuoteData.promoCode;
  const promotionCode = (props.offer.recommendedPremium.discounts.length > 0) ? props.offer.recommendedPremium.discounts[0].discountCode : "[Empty]";  
  const grossPremium = props.offer.recommendedPremium.grossPremium.amount;  
  const originalGrossPremium = props.offer.recommendedPremium.originalGrossPremium.amount;    

  return (
    <div className="summary-container">
      <div
        className="summary-title"
        style={{ color: props.detailSummaryTitleColor || '#000' }}
      >
        {props.quoteForm.quoteSummaryTranslation.summary}
      </div>
      <hr />
      <div className="summary-body">
        {
          quoteFormData && quoteFormData.tripType && quoteFormData.tripType === 'single' &&
          <div>{`${props.quoteForm.quoteSummaryTranslation.startDate} `}<b>{moment(props.quoteFormFront.dateRange.startDate).format('L')}</b> {`${props.quoteForm.quoteSummaryTranslation.endDate} `}
            <b>{moment(props.quoteFormFront.dateRange.endDate).format('L')}</b>
          </div>
        }
        {
          quoteFormData && quoteFormData.tripType && quoteFormData.tripType === 'annual' &&
          <div>{`${props.quoteForm.quoteSummaryTranslation.annualStartDate} `}<b>{moment(props.quoteFormFront.dateRange.startDate).format('L')}</b> {`${props.quoteForm.quoteSummaryTranslation.annualEndDate} `}
            <b>{moment(props.quoteFormFront.dateRange.endDate).format('L')}</b>
          </div>
        }
        <br />
        <div>{`${props.quoteForm.quoteSummaryTranslation.productName} `}<b>{props.offer.productOfferingName}</b></div>
        <br />
        {
          IsPromocodeEnable && promotionCode !== "[Empty]" &&
          <div>
            {`${props.quoteForm.quoteSummaryTranslation.promoCode} `}<b>{ promotionCode }</b>            
          </div>
        }
        {
          IsPromocodeEnable && promotionCode !== "[Empty]" &&
          <div>
            <br />          
          </div>
        }        
        <div>{`${props.quoteForm.quoteSummaryTranslation.travelFromCountry} `}
          <b>{countryName(props.quoteFormFront.departureCountry)}</b>
          {` ${props.quoteForm.quoteSummaryTranslation.travelTo} `}
          <b>{countryName(props.quoteFormFront.destination)}</b>
        </div>
        <br />
        <div>{`${props.quoteForm.quoteSummaryTranslation.currencyDetails} `}<b>{props.DCX.currency ? props.DCX.currency : ''}</b>
        </div>
        <br />
        {
          props.quoteForm.mainQuoteData.travellers &&
          <div>
            {
              props.quoteForm.mainQuoteData.travellers == 'number' && props.quoteFormFront.travellers} {props.quoteFormFront.numberOfTravellers > 1
                ? props.quoteForm.quoteSummaryTranslation.travellers
                : props.quoteForm.quoteSummaryTranslation.traveller
            }
            {
              props.quoteForm.mainQuoteData.travellers != 'number' &&
              Object.keys(props.quoteFormFront.travellers).map(ageband => (
                <div key={ageband}>
                  {
                    props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.agebandLabels &&
                    <b>{`${props.quoteFormFront.travellers[ageband]} ${travellerTranslation(ageband)}`}</b>
                  }
                </div>
              ))
            }
          </div>
        }
        <br />
        <TermsAndConditions offer={props.offer} quoteSummaryTranslation={props.quoteForm.quoteSummaryTranslation} />
      </div>
      <div
        className="summary-footer"
        style={{ background: props.detailSummaryPanelColor }}
      >
        {
          !IsPromocodeEnable &&
          <div className="price">
            {`${displayPrice(props.offer.recommendedPremium.grossPremium.amount)} ${props.DCX.currency ? props.DCX.currency : ''}`}
          </div>
        }        
        {
          IsPromocodeEnable && grossPremium === originalGrossPremium &&
          <div className="price">
            {`${displayPrice(props.offer.recommendedPremium.grossPremium.amount)} ${props.DCX.currency ? props.DCX.currency : ''}`}
          </div>
        }   
        {
          IsPromocodeEnable && grossPremium < originalGrossPremium &&
          <div className="price">
            {`${displayPrice(grossPremium)}/`}
            <s>{`${displayPrice(originalGrossPremium)} `} </s>
            {` ${props.DCX.currency ? props.DCX.currency : ''} `}
          </div>
        }             
        {
          props.showTaxes &&
          iptTax &&
          <div className="ipt-tax">
            {props.quotationIptTaxesLabel} {displayPrice(iptTax.totalTaxAmount.amount)} {iptTax.totalTaxAmount.currency}            
          </div>
        }
        <div>{props.quoteForm.quoteSummaryTranslation.totalPrice}</div>
      </div>
    </div>
  );
}
