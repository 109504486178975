/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';

type Props = {
  names: string[],
  fields: Object[],
  changeForm: Function,
  min: number,
  max: number,
  placeholder: string,
}

type State = {
  open: boolean
}

export default class SingleSelectPassenger extends React.Component<Props, State> {
  state = {
    open: false,
  };
  static defaultProps = {
    min: 0,
    max: 99,
  };

  changeValue = (fieldName, value) => {
    this.props.changeForm(fieldName, value);
  };
  increment = (fieldName, value) => {
    if (value < this.props.max) this.changeValue(fieldName, value + 1);
  };
  decrement = (fieldName, value) => {
    if (value > this.props.min) this.changeValue(fieldName, value - 1);
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { value, name } = this.props.input;
    return (
      <div className="single-select-passenger">
        <div className="input-view">
          <span>{value || 0}</span>
          <div className="values">
            <button
              type="button"
              className="left"
              onClick={() => this.decrement(`${name}`, value || 0)}
            />
            <button
              type="button"
              className="right"
              onClick={() => this.increment(`${name}`, value || 0)}
            />
          </div>
        </div>
      </div>
    );
  }
}
