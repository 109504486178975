/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';

type Props = {
  input: {
    trueValue: string,
    onChange: Function,
  },
  changeQuoteForm: Function,
  label: string | React.ReactNode,
  meta: Object,
  errorMsg: string,
  trueVal: string,
  falseVal: string,
}

type State = {
  selected: string
}

class CustomCheckbox extends React.Component<Props, State> {
  state = {
    selected: false,
  };

  static getDerivedStateFromProps(props: Props) {
    return {
      selected: props.input.value === (props.trueVal || true),
    };
  }

  handleChange = () => {
    if (this.state.selected) {
      this.setState({ selected: false });
      this.props.input.onChange(this.props.falseVal || null);
    } else {
      this.setState({ selected: true });
      this.props.input.onChange(this.props.trueVal || true);
    }
  };

  render() {
    return (
      <div className="field-radio">
        <div className="container-radio">
          <div className="input-radio input-radio--lonely">
            <div
              className={`check-simul ${this.state.selected ? 'check-simul--selected' : ''}`}
              onClick={this.handleChange}
            />
            <label className="label" onClick={this.handleChange}>{this.props.label}</label>
          </div>
        </div>
        {this.props.meta.error && this.props.meta.touched &&
        <span className="text-error">{this.props.errorMsg || this.props.meta.error}</span>}
      </div>
    );
  }
}

export default CustomCheckbox;
