// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Props = {
  footer: Object,
  footerContent: Object,
  lang: string,
  country: string,
  partner: string,
  switches: Object,
}

class Footer extends React.Component<Props, State> {
  redirect = (link) => {
    window.location.href = link + window.location.search;
  };

  render() {    
    return (
      <div className="footer">
        <div className="bottom-bar">
          <div className="document-container">
            {
              /* this.props.footer.map(document => (
                document && document.url &&
                <a key={document.label} onClick={() => this.redirect(document.url)} target="_blank" className="document">
                  {document.label}
                </a>
              )) */
              this.props.footer.map((document, index) => (
                document && document.url &&
                <a key={index} onClick={() => this.redirect(document.url)} target="_blank" className="document">
                  {document.label}
                </a>
              ))
            }
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
          </div>
        </div>
        <div
          className="footer-content rich-text-content base-content"
          dangerouslySetInnerHTML={{ __html: this.props.footerContent }}
        />
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({}),
  {},
)(Footer));
