/* eslint-disable react/self-closing-comp */
// @flow

import React from 'react';
import { withRouter } from 'react-router';
import { format } from 'react-string-format';
import bookingPathApi from '../../network/api/bookingPathApi';
import { getEnvironment } from '../../helper/helper';

type State = {
  content: ?string
}

class NoticePage extends React.Component<Props, State> {
  state = {
    content: null,
  };
  componentDidMount(): void {
    bookingPathApi.getNoticePage(this.props.match.params, this.props.siteData.data.lineOfBusiness)
      .then(response => response.text())
      .then(content => this.setState({ content }));

    //! ECOMDEV-40085 - Adobe script implementation
    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6].includes('?') ? PageURLElements[6].substring(0, PageURLElements[6].indexOf('?')) : PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Title
    // document.title = 'Notice Page';

    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Notice Page',
          pageType: 'Notice Page',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
    };
    // console.log('-- Notice Page --');
    // console.log(window.digitalData);
  }

  render() {
    if (!this.state.content) return false;
    return (
      <div className="container-html-content">     
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.state.content }} />
      </div>
    );
  }
}

export default withRouter(NoticePage);