/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
// @flow

import React from 'react';

type Props = {
  input: {
    value: string,
    onChange: Function
  },
  meta: Object,
  label: string,
  list: string[],
  listType?: string,
  responsive?: boolean,
  statusLabels?: ?Object,
  placeholder: string,
  selectablePlaceholder?: boolean,
  validate: ((Object) => boolean)[],
  disabled: boolean
}

export const LIST_TYPE_NUMBERS = 'numbers';

export default class SelectFieldPreview extends React.PureComponent<Props> {
  render() {
    const {
      listType, list, statusLabels, responsive, placeholder, meta, errorMsg, validate, input, disabled, selectablePlaceholder, label,
    } = this.props;
    const sortFunction = listType === LIST_TYPE_NUMBERS ? (a, b) => parseInt(a, 10) - parseInt(b, 10)
      : (a, b) => a.toString().localeCompare(b.toString(), 'en');
    const keys = statusLabels ? Object.keys(statusLabels) : list.sort(sortFunction);
    return (
      <div className={`preview-field ${responsive ? 'preview-field--responsive' : ''}`}>
        <div className="label">{label}</div>
        <select
          {...input}
          required={!!validate}
          aria-label={input.name}
          disabled={disabled}
        >
          <option
            value=""
            className="placeholder-option"
            disabled={!selectablePlaceholder}
          >{placeholder}
          </option>
          {
            keys
              .map((elem, i) => (
                <option
                  key={input.name + elem + i}
                  value={elem}
                >{statusLabels ? statusLabels[elem] : elem}
                </option>
              ))
          }
        </select>
        {meta.error && meta.touched &&
        <span className="text-error">{errorMsg || meta.error}</span>}
      </div>
    );
  }
}

SelectFieldPreview.defaultProps = {
  responsive: false,
  range: null,
  statusLabels: null,
};
