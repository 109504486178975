// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { buildFrontUrl } from '../../../routes';

type Props = {
  allianzLogo: Object,
  partnerLogo: Object,
  switches: Object,
  data: {
    allianzLogoAlt: string,
    allianzLogo: string,
    partnerLogoAlt: string,
    partnerLogo: string,
    partnerUrl: string
  },
  header: Object[],
  match: Object,
}

type State = {
  showMenu: boolean,
  languageOpen: boolean,
  countryOpen: boolean,
}

const redirect = (link) => {
  window.location.href = link + window.location.search;
};


const DisplayList = ({
  label, current, urls, action, open, showPicto,
}) => (
  <div className="switch-language">
    <div className="label" onClick={action}>{label}</div>
    <div className={`container-list${open ? ' container-list--open' : ''}`}>
      {
        urls.length > 0 && urls.map(url => (
          <a key={url.langCode} onClick={() => redirect(url.url)}> {url.countryName} </a>
        ))
      }
    </div>
  </div>
);

class Header extends React.Component<Props, State> {
  affiliation = this.props.affiliation;
  state = {
    showMenu: false,
    languageOpen: false,
    countryOpen: false,
  };
  toggleLanguage = () => {
    this.setState(prev => ({ languageOpen: !prev.languageOpen, countryOpen: false }));
  };
  toggleCountry = () => {
    this.setState(prev => ({ countryOpen: !prev.countryOpen, languageOpen: false }));
  };

  render() {
    const enoughToShow = this.props.header.length > 1;
    return (
      <div className={`header-site ${enoughToShow ? 'mr-bt' : ''}`}>
        <div className="container-switches">
          {
            this.props.switches && this.props.switches.switchLanguage &&
            <DisplayList
              label={this.props.switches.switchLanguage.labelSelect}
              urls={this.props.switches.switchLanguage.urls}
              current={this.props.switches.switchLanguage.current}
              open={this.state.languageOpen}
              action={this.toggleLanguage}
            />

          }
        </div>
        <div className="content-header base-content">
          {
            (this.props.partnerLogo || (this.affiliation && this.affiliation.logo)) &&
            <a href={this.affiliation ? this.affiliation.logo.logoLink : this.props.data.partnerUrl} target="_blank" rel="noreferrer">
              <img
                id="partner-logo"
                className="image"
                src={this.affiliation ? this.affiliation.logo.logoUrl : this.props.partnerLogo}
                alt={this.affiliation ? this.affiliation.logo.logoAlt : this.props.partnerLogoAlt}
              />
            </a>
          }
          {
            enoughToShow &&
            <div className="icon" style={{ background: this.props.data.mobileNavBar }}>
              <div
                className="fa fa-bars"
                onClick={() => this.setState(prevState => ({ showMenu: !prevState.showMenu }))}
              />
            </div>
          }

          <div
            className={`document-container ${this.state.showMenu ? 'document-container--responsive' : ''}`}
            style={{ background: `${this.state.showMenu ? this.props.data.mobileNavBar : 'initial'}` }}
          >
            {
              this.props.header.map((document, index) => (
                document &&
                <Fragment key={document.label}>
                  {
                    document.url &&
                    <a
                      href={index === 0 ? buildFrontUrl(this.props.match.params, '') : document.url}
                      rel="noopener noreferrer"
                      target={index === 0 ? '' : '_blank'}
                      className={`document ${index === 0 && enoughToShow ? 'link-home' : ''}`}
                    >
                      {index === 0 ? document.label || 'Home' : document.label}
                    </a>
                  }
                </Fragment>
              ))
            }
          </div>
          {
            this.props.allianzLogo &&
            <img id="allianz-logo" className="image" src={this.props.allianzLogo} alt={this.props.data.allianzLogoAlt} />
          }
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  () => ({}),
  {},
)(Header));
