/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import type { QuoteFormType } from '../../../../types/QuoteFormTypes';
import CustomDatePicker, { futureYears } from '../../../../components/CustomDatePicker';

type Props = {
  input: {
    value: {
      startDate: Date,
      endDate: Date,
    },
    name: string,
  },
  quoteData: QuoteFormType,
  startDate: string,
  endDate: string,
  meta: Object,
  dateTime?: boolean,
  startTime?: boolean,
  endTime?: boolean,
  LOB: string,
  tripType: Object,
}

type State = {
  focused: ?boolean,
  focusedEnd: ?boolean,
  startDate: Date,
  endDate: Date,
}

const manageEndDate = (startDate, currentEndDate) => {
  const copyStart = startDate.clone();
  copyStart.add(1, 'years').subtract(1, 'days');
  return copyStart;
};

class AnnualDatesOfTrip extends React.Component<Props, State> {
  state = {
    focused: null,
    focusedEnd: null,
  };

  componentDidMount() {
    if (!document.getElementById('dateNew')) {
      if (document.getElementById('date')) {
        document.getElementById('date');
      }
    }
    if (!document.getElementById('dateInputScreenNew')) {
      if (document.getElementById('DateInput__screen-reader-message-date')) {
        document.getElementById('DateInput__screen-reader-message-date').id = 'dateInputScreenNew';
      }
    }
  }

  componentWillReceiveProps(nextProps): void {
    /* ECOMDEV-39855 - [CH] eMagin  PROD (DCX=TMB) WL Webpage with ?agency= in URL doesn't work
    if (this.props.form && this.props.form.values) {
      if (nextProps.form.values.tripType !== this.props.form.values.tripType && nextProps.form.values.tripType === 'annual') {
        const startDate = moment().diff(this.props.input.value.startDate, 'days') === 0 ? moment().add(1, 'days') : this.props.input.value.startDate;
        this.props.input.onChange({
          ...this.props.input.value,
          startDate,
          endDate: manageEndDate('annual', startDate, this.props.input.value.endDate),
        });
      } else if (nextProps.form.values.tripType !== this.props.form.values.tripType && nextProps.form.values.tripType !== 'annual') {
        this.props.input.onChange({
          ...this.props.input.value,
          startDate: this.props.input.value.startDate,
          endDate: this.props.input.value.startDate,
        });
      }
    }
    */
  }

  render() {
    const { quoteData: { mainQuoteData }, form } = this.props;
    if (!form) return false;
    if (mainQuoteData.tripType !== form.values.tripType && mainQuoteData.tripType === 'annual') form.values.tripType = 'annual';
    const isAnnual = form.values.tripType === 'annual';
    const startDate = moment().diff(this.props.input.value.startDate, 'days') === 0 ? moment().add(1, 'days') : this.props.input.value.startDate;
    return (
      <div className="preview-field">
        {
          (!this.props.quoteData.mainQuoteData.tripType || (this.props.quoteData.mainQuoteData.tripType && this.props.quoteData.mainQuoteData.tripType === 'annual')) &&
          <div className="container-select">
            <div className="preview-field preview-field--responsive" id="start date">
              <CustomDatePicker
                id={this.props.startDate}
                label={this.props.startDate}
                showDefaultInputIcon={this.props.calendarIcon}
                // eslint-disable-next-line no-confusing-arrow
                isOutsideRange={day => !isAnnual ? day.isBefore(moment().startOf('day')) : day.isBefore(moment().add(1, 'days').startOf('day'))}
                date={startDate || moment()}
                onDateChange={startDate => this.props.input.onChange({
                  ...this.props.input.value,
                  startDate,
                  endDate: manageEndDate(startDate, this.props.input.value.endDate),
                })}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                placeholder={moment().format('L')}
                years={futureYears}
                normalize={(val) => val || moment()}
              />
            </div>
            <div className="preview-field preview-field--responsive" id="end date">
              <CustomDatePicker
                id={this.props.endDate}
                label={this.props.endDate}
                disabled={isAnnual}
                isOutsideRange={day => day.isBefore(this.props.input.value.startDate ? moment(this.props.input.value.startDate) : moment())}
                date={manageEndDate(startDate, this.props.input.value.endDate) || moment()}
                onDateChange={endDate => this.props.input.onChange({ ...this.props.input.value, endDate })}
                focused={this.state.focusedEnd}
                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                placeholder={moment().format('L')}
                years={futureYears}
                normalize={(val) => val || moment()}
              />
            </div>
          </div>
        }
        {this.props.meta.error && this.props.meta.touched &&
        <span className="text-error">{this.props.meta.errorMsg || this.props.meta.error}</span>}
      </div>
    );
  }
}

AnnualDatesOfTrip.defaultProps = {
  dateTime: false,
  startTime: false,
  endTime: false,
};

export default connect(state => ({
  form: state.form.quoteFormFront,
}))(AnnualDatesOfTrip);
