/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
// @flow

import React from 'react';

type Props = {
  fields: Object[],
  changeForm: Function,
  min: number,
  max: number,
  placeholder: string,
  travellersWording: string
}

type State = {
  open: boolean
}

export default class MultiSelectPassenger extends React.Component<Props, State> {
  state = {
    open: false,
  };
  static defaultProps = {
    min: 0,
    max: 99,
  };

  changeValue = (fieldName, value) => {
    this.props.changeForm(fieldName, value);
  };
  increment = (fieldName, value) => {
    if (value < this.props.max) this.changeValue(fieldName, value + 1);
  };
  decrement = (fieldName, value) => {
    if (value > this.props.min) this.changeValue(fieldName, value - 1);
  };

  close = () => {
    this.setState({ open: false });
  };
  toggle = () => {
    this.setState(prev => ({ open: !prev.open }));
  };

  resultPhrase = (value) => {
    if (!value || Object.keys(value).filter(key => !!value[key]).length === 0) return;
    if (Object.keys(value).filter(key => !!value[key]).length === 1) {
      return Object.keys(value)
        .filter(key => !!value[key])
        .map(key => `${value[key]} ${this.props.fields.find(field => field.name === key).label}`)
        .join(', ');
    }
    // eslint-disable-next-line prefer-template
    return Object.keys(value)
      .filter(key => !!value[key])
      // eslint-disable-next-line arrow-body-style
      .reduce((acc, key) => {
        return acc + value[key];
      }, 0) + ' ' + this.props.travellersWording;
  };

  render() {
    const { open } = this.state;
    const { value, name } = this.props.input;
    const resultPhrase = this.resultPhrase(value);
    return (
      <div className="multi-select-passenger">
        <div className="input-view" onClick={this.toggle}>
          <div className="result-phrase">{resultPhrase || this.props.placeholder}</div>
        </div>
        <div className={`select-space${open ? ' select-space--open' : ''}`}>
          <div className="container-list">
            {
              this.props.fields && this.props.fields.map(field => (
                <div key={field.name} className="line">
                  <span><strong>{field.label}</strong>
                    {field.labelDesc && field.labelDesc.length > 0 ? <br /> : null}
                    {field.labelDesc && field.labelDesc.length > 0 ? field.labelDesc : null}
                  </span>
                  <div className="values">
                    <button
                      type="button"
                      className="left"
                      onClick={() => this.decrement(`${name}.${field.name}`, value[field.name] || 0)}
                    />
                    <span>{value[field.name] || 0}</span>
                    <button
                      type="button"
                      className="right"
                      onClick={() => this.increment(`${name}.${field.name}`, value[field.name] || 0)}
                    />
                  </div>
                </div>
              ))
            }
          </div>
          <div className="container-buttons">
            <button type="button" onClick={() => this.props.changeForm(name, null)}>
              <i className="fa fa-times red" />
            </button>
            <button type="button" onClick={this.close}>
              <i className="fa fa-check green" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
