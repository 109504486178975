// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FORM_CONFIRMATION, FORM_PAYMENT_DETAILS, FORM_PERSONAL_DETAILS } from '../../../const';
import Summary from './Summary';
import type { DCXType } from '../../../../../admin-ui/src/types/DCXTypes';
import { queryParams } from '../../../routes';
import {
  loadCountriesIfNeeded,
  pickGeozonesTranslation,
  toExploitableQuoteData,
} from '../../../state/quoteForm/quoteFormActions';

type Props = {
  match: Object,
  history: Object,
  currentStep: integer,
  steps: string[],
  children: React.Children,
  continue: string,
  back: string,
  callToAction: string,
  DCX: DCXType;
  quoteForm: Object;
  quoteFormFront: Object;
  location: Object,
  dispatch: Function,
  handleSubmit: Function,
  countries: Object[],
  quotationIptTaxesLabel: ?string,
  showTaxes: boolean,
}

class StepsContainer extends React.Component<Props> {
  handleSubmit = (data) => {
    this.props.handleSubmit(this.props.children.props.handleSubmit)(data);
    if (this.props.valid && this.props.match.params.step !== FORM_PERSONAL_DETAILS) {
      this.props.history.push({
        pathname: this.props.continue,
        search: window.location.search,
      });
    }
  };

  componentDidMount() {
    const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));

    this.props.dispatch(loadCountriesIfNeeded(this.props.match.params.langCode));

    let data = JSON.parse(localStorage.getItem(this.props.children.props.name));
    data = { ...data, ...queryParams };
    if (data) {
      if (data.dateOfBirth) {
        data.dateOfBirth = moment(data.dateOfBirth);
      }
      if (data.passenger) {
        data.passenger = data.passenger.map(p => ({ ...p, dateOfBirth: moment(p.dateOfBirth) }));
      }
      if (this.props.children.props.name === 'personalDetails') {
        data = {
          ...data,
          country: quoteFormData.residenceCountry,
        };
      }
    }
    this.props.dispatch(initialize('stepForm', data));
  }

  componentWillReceiveProps(nextProps) {
    const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));
    if (this.props.children !== nextProps.children) {
      let data = JSON.parse(localStorage.getItem(nextProps.children.props.name));
      data = { ...data, ...queryParams };
      if (data) {
        if (data.dateOfBirth) {
          data.dateOfBirth = moment(data.dateOfBirth);
        }
        if (data.passenger) {
          data.passenger = data.passenger.map(p => ({ ...p, dateOfBirth: moment(p.dateOfBirth) }));
        }
        if (nextProps.children.props.name === 'personalDetails') {
          data = {
            ...data,
            country: quoteFormData.residenceCountry,
          };
        }
        this.props.dispatch(initialize('stepForm', data));
      }
    }
  }

  render() {
    return (
      <div className="body-site">
        <div className="body-content">
          <div className="flex-display full-start base-content">
            <div
              style={{ borderTop: `4px solid ${this.props.callToAction}` }}
              className="booking-path-container up full"
            >
              <div className="steps-container">
                {
                  this.props.steps && Object.keys(this.props.steps).filter((s, i) => i < this.props.stepsNumber).map((step, i) => (
                    <div
                      className="step-container"
                      key={step}
                    >
                      <div
                        style={{
                          borderColor: this.props.callToAction,
                          color: this.props.currentStep !== i ? this.props.callToAction : '',
                          background: this.props.currentStep === i ? this.props.callToAction : '',
                        }}
                        className="step-square"
                      >
                        <span>{i + 1}</span>
                      </div>
                      <div
                        className="step-title"
                        style={{ color: this.props.callToAction }}
                      >
                        {this.props.steps[step] || (i + 1)}
                      </div>
                    </div>
                  ))
                }
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="booking-path-content">
                  {this.props.children}
                </div>

                {this.props.match.params.step !== FORM_CONFIRMATION &&
                <Fragment>
                  <hr />
                  <div className="booking-path-footer">
                    <a
                      className="button left"
                      onClick={() => {
                        if (this.props.resetPurchaseError) {
                          this.props.resetPurchaseError();
                        }
                        this.props.history.push({
                          pathname: `${this.props.back}`,
                          search: window.location.search,
                        });
                      }}
                    >
                      {this.props.stepInfos.personalDetailsBack || this.props.stepInfos.paymentDetailsBack || 'BACK'}
                    </a>
                    {
                      this.props.match.params.step !== FORM_PAYMENT_DETAILS &&
                      <button
                        className="button right"
                        style={{ background: this.props.callToAction }}
                        type="submit"
                      >
                        {this.props.stepInfos.personalDetailsContinue || 'CONTINUE'}
                      </button>
                    }
                  </div>
                </Fragment>
                }
              </form>
            </div>
            <Summary
              geozones={pickGeozonesTranslation(this.props.DCX.geozones, toExploitableQuoteData(this.props.quoteForm).quoteLabelTranslation.geozones)}
              callToAction={this.props.callToAction}
              detailSummaryTitleColor={this.props.detailSummaryTitleColor}
              detailSummaryPanelColor={this.props.detailSummaryPanelColor}
              DCX={this.props.DCX}
              quoteForm={this.props.quoteForm}
              quoteFormFront={JSON.parse(localStorage.getItem('quoteFormFront'))}
              offer={JSON.parse(localStorage.getItem('contract'))}
              countries={this.props.countries}
              quotationIptTaxesLabel={this.props.quotationIptTaxesLabel}
              showTaxes={this.props.showTaxes}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  countries: state.quoteForm.countries,
}))(reduxForm({
  form: 'stepForm',
})(StepsContainer)));
