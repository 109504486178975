/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
// @flow

import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { QuoteFormType } from '../../../types/QuoteFormTypes';
import type { QuotationRQ, QuotationRS } from '../../../types/QuotationTypes';
import QuoteForm from './QuoteForm';
import {
  initializeQuoteFormFront,
  loadCountriesIfNeeded,
  notifyCustomQuotationError,
  toExploitableQuoteData, toggleSubmitting,
} from '../../../state/quoteForm/quoteFormActions';
import { atLeastOne, dateRange, fieldEmpty, isNumber } from '../../../components/validation/ValidationFunctions';
import type { DCXType } from '../../../types/DCXTypes';
import EQSApi from '../../../network/api/eqsApi';
import { FORM_PRODUCT_QUOTE, FORM_ROOT_PAGE_ROUTE, QUOTE_FORM_PARAMS } from '../../../const';
import { buildFrontUrl, queryParams, siteVersion } from '../../../routes';
import { toLocalDate } from '../../../dates';
import { Store } from 'react-notifications-component';

import mockNoDiscountData from '../../../network/mock/mockNoDiscountData.json';
import mockDiscountAllData from '../../../network/mock/mockDiscountAllData.json';
import mockDiscountInvalidData from '../../../network/mock/mockDiscountInvalidData.json';
import mockDiscountMixData from '../../../network/mock/mockDiscountMixData.json';

type Props = {
  countries: Object[],
  LOB: string,
  quoteForm: QuoteFormType,
  quoteFormFront: QuoteFormType,
  fetchCountries: Function,
  initializeQuoteFormFront: Function,
  match: Object,
  DCX: DCXType,
  history: Object,
  notifyCustomQuotationError: Function,
  toggleSubmitting: Function,
};

type State = {
  submiting: boolean,
  initialized: boolean,
  displayError: boolean,
};

const cleanDate = (date, hours, minutes) => {
  const cleanedDate = date ? moment(date).startOf('day') : moment();

  if (hours || minutes) {
    return cleanedDate
      .add(hours, 'hours')
      .add(minutes || 0, 'minutes');
  }
  return cleanedDate.format();
};

//! ECOMDEV-39041 - Fix bug trip type not change when user select annual type on admin-ui
const cleanDates = (quoteTrip, dateRange) => {
  let startDate;
  let endDate;

  if (!dateRange) {
    return { startDate: '', endDate: '' };
  }

  // eslint-disable-next-line prefer-const
  startDate = cleanDate(dateRange.startDate, dateRange.startHours, dateRange.startMinutes);

  if (quoteTrip === 'annual') {
    const copyStart = dateRange.startDate.clone();
    endDate = copyStart.add(1, 'years').subtract(1, 'days');
  } else {
    endDate = cleanDate(dateRange.endDate, dateRange.endHours, dateRange.endMinutes);
  }
  return {
    startDate,
    endDate,
  };
};

const countNumberOfTravellers = (quoteFormFrontData: Object) => {
  const travellersData = quoteFormFrontData.travellers;
  if (typeof travellersData === 'number' || typeof travellersData === 'string') {
    return parseInt(travellersData, 10);
  } else if (typeof travellersData === 'object') {
    return Object
      .keys(travellersData)
      .reduce((count, type) => count + parseInt(travellersData[type], 10), 0);
  }
};


class QuoteFormContainer extends React.Component<Props, State> {
  state = {
    submiting: false,
    initialized: false,
    displayError: false,
  };
  errorMsg = {
    departureCountryErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.departureCountryErrorMsg || 'error', 'departure'),
    residenceCountryErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.residenceCountryErrorMsg || 'error', 'residence'),
    destinationErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.destinationErrorMsg || 'error', 'destination'),
    dateRangeErrorMsg: dateRange(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.startDateMsg, this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.endDateMsg),
    endDateErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.endDateMsg || 'error', 'end'),
    numberOfTravellersErrorMsg: atLeastOne(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.numberOfPassengerErrorMsg || 'error'),
    priceErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.priceErrorMsg || 'error'),
    numberOfTicketsErrorMsg: isNumber(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.numberOfTicketsErrorMsg || 'error'),
    rentalObjectErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.rentalObjectErrorMsg || 'error'),
    bookingDateErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.bookingDateErrorMsg || 'error'),
    tripTypeErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.tripTypeErrorMsg || 'error'),
    carHireReferenceErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.carHireReferenceErrorMsg || 'error'),
    employeeTravelDurationErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.employeeTravelDurationErrorMsg || 'error'),
    numberOfTravellingDaysPerYearErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.numberOfTravellingDaysPerYearErrorMsg || 'error'),
    insuranceStartDateErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.insuranceStartDateErrorMsg || 'error'),
    relationshipStatusErrorMsg: fieldEmpty(this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.relationshipStatusErrorMsg || 'error'),
  };

  acceptedLob = (lobs: string[]) => lobs.filter(lob => lob === this.props.LOB).length > 0;

  buildTravellers = (quoteData) => {
    const { travellers } = this.props.quoteForm.mainQuoteData;
    const builtTravellers = [];
    if (travellers === 'number') {
      for (let i = 0; i < parseInt(quoteData.travellers); i++) {
        builtTravellers.push({
          perTravelerWithAgeBand: {
            numberOfTravelers: 1,
            pricePerTraveler: {
              amount: '0',
              currency: this.props.DCX.currency || '',
            },
          },
        });
      }
    } else {
      Object.keys(quoteData.travellers || {}).forEach((ageband) => {
        for (let i = 0; i < parseInt(quoteData.travellers[ageband], 10); i++) {
          builtTravellers.push({
            perTravelerWithAgeBand: {
              numberOfTravelers: 1,
              ageCategory: ageband,
              pricePerTraveler: {
                amount: '0',
                currency: this.props.DCX.currency || '',
              },
            },
          });
        }
      });
    }
    return builtTravellers;
  };

  buildQuotationRQ: QuotationRQ = quoteData => {
    const quotationRQ = {
      marketScope: this.props.match.params.countryCode,
      businessPartner: this.props.match.params.partnerCode,
      salesChannelInformation: {
        salesChannelCode: 'WL',
      },
      language: this.props.match.params.langCode,
      currency: this.props.DCX.currency || '',
      requestDate: moment().format(),
      travels: [{
        residence: {
          countryCode: quoteData.residenceCountry,
        },
        hasRentalProperty: quoteData.rentalObject,
        travelersRelationshipType: quoteData.relationshipStatus,
        // eslint-disable-next-line no-nested-ternary
        travelType: quoteData.tripType === 'single' ? 'one-way-trip' : quoteData.tripType === 'annual' ? 'Annual-Trip' : '',
        totalValue: {
          amount: quoteData.tripCost,
          currency: this.props.DCX.currency || '',
        },
        bookingDate: toLocalDate(quoteData.bookingDate),
        originDate: toLocalDate(quoteData.dateRange.startDate),
        departure: {
          originLocationType: 'country',
          originLocationName: quoteData.departureCountry || this.props.match.params.countryCode,
        },
        destinations: [{
          destinationDate: toLocalDate(quoteData.dateRange.endDate),
          destinationLocationType: this.props.quoteForm.mainQuoteData.destination === 'countries' ? 'country' : 'zone',
          destinationLocationName: quoteData.destination,
        }],
        travellers: [{
          categoryName: this.buildTravellers(quoteData),
        }],
      }],
    };
  
    // Add discounts only if promoCode is not null or empty
    if (quoteData.promoCode) {
      quotationRQ.discounts = [{
        code: quoteData.promoCode,
      }];
    }
    return quotationRQ;
  };
  

  handleSubmit = tripType => (quoteData) => {
    const tripDates = cleanDates(tripType, quoteData.dateRange);
    const quoteFormToSave = {
      ...quoteData,
      tripType: quoteData.tripType || tripType,
      residenceCountry: quoteData.residenceCountry ? quoteData.residenceCountry : this.props.match.params.countryCode,
      bookingDate: quoteData.bookingDate ? cleanDate(quoteData.bookingDate) : null,
      dateRange: {
        startDate: tripDates.startDate,
        endDate: tripDates.endDate,
      },
    };

    //! ECOMDEV-39041 - Fix bug trip type not change when user select annual type on admin-ui
    if (tripType === 'annual') {
      quoteFormToSave.tripType = 'annual';
    }
     
    if (quoteFormToSave.bookingDate === null) {
      delete quoteFormToSave.bookingDate;
    }

    const quotationRQ = this.buildQuotationRQ(quoteFormToSave);
    quoteFormToSave.numberOfTravellers = countNumberOfTravellers(quoteFormToSave);

    localStorage.setItem('quoteFormFront', JSON.stringify(quoteFormToSave));
    localStorage.setItem('quotationRQ', JSON.stringify(quotationRQ));

    this.props.toggleSubmitting(true);

    //! ECOMDEV-40833 - White Label Factory - Add discount functionality 
    //! ECOMDEV-40833 - Need to recheck quotationRQ (preload) & quotationRS (Response)

    //! create mockData in localStorage to use mockData and by pass product from eQS   
    let useMockData = false;
    let mockDataCollection = '';
    const storedMockData = localStorage.getItem('mockData');
    const parsedMockData = storedMockData ? JSON.parse(storedMockData) : null;

    if (parsedMockData) {
      useMockData = parsedMockData.useMockData;
      mockDataCollection = parsedMockData.mockData;
    } else {      
      const mockData = {
        useMockData: false,
        mockData: "mockDiscountAllData",
        mockCollection1: "mockDiscountAllData",
        mockCollection2: "mockNoDiscountData",
        mockCollection3: "mockDiscountInvalidData",
        mockCollection4: "mockDiscountMixData"
      };      
      useMockData = false;
      mockDataCollection = "mockDiscountAllData";    
      localStorage.setItem('mockData', JSON.stringify(mockData));  
    }
    
    EQSApi.quotation({ contract: quotationRQ }, siteVersion)
      .then(response => response.json())
      .then((quotationRS: QuotationRS) => {                          
        if (useMockData) {
          // Using mock data from local storage
          let productMockData: QuotationRS;
          switch (mockDataCollection) {
            case "mockDiscountAllData":
              productMockData = mockDiscountAllData;
              localStorage.setItem('quotationRS', JSON.stringify(productMockData));
              break;
            case "mockNoDiscountData":
              productMockData = mockNoDiscountData;
              localStorage.setItem('quotationRS', JSON.stringify(productMockData));
              break;
            case "mockDiscountInvalidData":
              productMockData = mockDiscountInvalidData;
              localStorage.setItem('quotationRS', JSON.stringify(productMockData));
              break;
            case "mockDiscountMixData":
              productMockData = mockDiscountMixData;
              localStorage.setItem('quotationRS', JSON.stringify(productMockData));
              break;
            default:
              productMockData = mockDiscountAllData;
              localStorage.setItem('quotationRS', JSON.stringify(productMockData));
              break;
          }                       
        }
        else
        {
          // Using real data from eQS Quotation engine
          localStorage.setItem('quotationRS', JSON.stringify(quotationRS)); 
        }
        this.props.history.push(buildFrontUrl(this.props.match.params, `${FORM_ROOT_PAGE_ROUTE}/${FORM_PRODUCT_QUOTE}`));
        this.props.toggleSubmitting(false);
      })
      .catch((e) => {
        this.props.toggleSubmitting(false);
        this.props.notifyCustomQuotationError();
        console.log('Quotation error', e);
        this.props.history.push(buildFrontUrl(this.props.match.params, `${FORM_ROOT_PAGE_ROUTE}/${FORM_PRODUCT_QUOTE}`));
      });
  };

  componentDidMount() {
    if (!this.props.quoteFormFront) {
      this.props.initializeQuoteFormFront({}, this.props.match.params.countryCode);
    }
    this.props.fetchCountries(this.props.match.params.langCode);
  }

  filterQuery = (queryList) => {
    return queryList.filter(query => {
      return QUOTE_FORM_PARAMS.includes(query);
    });
  };

  componentWillReceiveProps() {
    const localQuoteForm = JSON.parse(localStorage.getItem('quoteFormFront'));
    if (this.props.quoteFormFront && !this.state.initialized && this.filterQuery(Object.keys(queryParams)).length > 0) {
      this.setState(() => ({ initialized: true }), () => this.props.initializeQuoteFormFront(
        {
          ...localQuoteForm,
          dateRange: {
            startDate: localQuoteForm && localQuoteForm.dateRange && moment(localQuoteForm.dateRange.startDate) || '',
            endDate: localQuoteForm && localQuoteForm.dateRange && moment(localQuoteForm.dateRange.endDate) || '',
          },
        },
        this.props.match.params.countryCode,
        { ...queryParams },
      ));
    }
  }

  render() {
    return (
      <div className="quote-form">
        <div className="titles" style={{ color: this.props.quoteForm.quoteStyle.fontColorTitle }}>
          <span
            className="title-quote"
          >{this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.titleLine1 ? this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.titleLine1 : 'TITLE 1'}
          </span>
          <div
            className="title-quote-2"
          >{this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.titleLine2 ? this.props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.titleLine2 : 'TITLE 2'}
          </div>
        </div>
        <div className="quote-form-content">
          <QuoteForm
            quoteData={toExploitableQuoteData(this.props.quoteForm)}
            quoteForm={this.props.quoteFormFront}
            save={this.handleSubmit(this.props.quoteForm.mainQuoteData.tripType)}
            tripType={this.props.quoteForm.mainQuoteData.tripType}
            LOB={this.props.LOB}
            acceptedLob={this.acceptedLob}
            countryList={this.props.countries}
            errorMsg={this.errorMsg}
            history={this.props.history}
            params={this.props.match.params}
            DCX={this.props.DCX}
          />
        </div>
        <div
          onClick={() => this.setState({ displayError: false })}
          style={{ display: this.state.displayError ? 'flex' : 'none' }}
          className="error-no-dcx"
        >
          <div className="message">
            {I18n.t('DCX.noDCX')}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    countries: state.quoteForm.countries,
    quoteFormFront: state.form.quoteFormFront,
    DCX: state.site.DCX,
  }),
  {
    fetchCountries: loadCountriesIfNeeded,
    initializeQuoteFormFront,
    toggleSubmitting,
    toExploitableQuoteData,
    notifyCustomQuotationError,
  },
)(QuoteFormContainer));
